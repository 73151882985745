import { Observable } from "rxjs";

export interface Integration {
  key?: string;
  isConfirmed?: boolean;
  organizationId?: string;
  integrationType?: number;
  stoneCode?: string;
}

export abstract class IntegrationData {
  abstract getIntegrations(organizationId: string): Observable<Integration[]>;
  abstract save(integration: Integration): Observable<Integration>;
}