import { Observable } from 'rxjs';

export interface DeviceModel {
  code?: number;
  description?: string;
}

export interface Device {
  serialNumber?: string;
  modelName?: string;
  model?: number;
  modelManufacturer?: string;
  modelType?: string;
  insertDateTime?: Date;
}


export abstract class DeviceDataData {
  abstract getAllDevices(key: string): Observable<DeviceModel[]>;
  abstract getAllTerminalsModels(): Observable<DeviceModel[]>;
  abstract getAll(): Observable<Device[]>;
  abstract create(device: Device): Observable<any>;
}