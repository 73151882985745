import { Observable } from 'rxjs';

export interface User {
  id: string;
  name?: string;
  email?: string;
  emailConfirmed?: string;
  isEmailConfirmed?: boolean;
}

export abstract class UserData {
  abstract getUsersByOrganization(key: string): Observable<User[]>;
  abstract getUsersByGroup(groupId: string): Observable<User[]>;
  abstract getAll(): Observable<User[]>;
}