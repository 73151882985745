import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { Config } from '../../../environments/config';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(@Inject('CONFIG') private config: Config, public authService: AccountService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken() ?? "";
    request = this.handleApiCommunication(request, token);
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // const tokenExpired = error.headers.get('token-expired');
        // if (tokenExpired) {
        //   return this.handle401Error(request, next);
        // }

        this.authService.logout();
        return throwError(error);
      } else {
        return throwError(error);
      }
    }));
  }

  handleApiCommunication(request: HttpRequest<any>, token: string = '') {
    let culture = "?culture=pt-BR";
    if (request.url.includes("?")) {
      culture = "&culture=pt-BR";
    }


    const customUrl = request.url.includes('/api/v1') ? `${this.config.apiUrl}${request.url}${culture}` : request.url;
    const tokenBearer = token ? `Bearer ${token}` : "";
    return request.clone({
      url: `${customUrl}`,
      setHeaders: {
        Authorization: tokenBearer,
        'X-Api-Key': this.config.apiKey
      }
    });
  }
}
