import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IntegrationData, Integration } from '../data/integration.data';

@Injectable()
export class IntegrationService extends IntegrationData {
    constructor(private http: HttpClient
    ) {
        super();
    }

    getIntegrations(organizationId: string): Observable<Integration[]> {
        return this.http.get<Integration[]>(
            `/api/v1/integration/organization/${organizationId}`
        );
    }

    save(integration: Integration): Observable<Integration> {
        return this.http.post<Integration>(`/api/v1/integration`, integration);
    }
}
