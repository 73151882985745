import { Injectable } from '@angular/core';
import { Organization } from '../data/organization.data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Group, GroupData } from '../data/group.data';
import { User } from '../data/users.data';

@Injectable()
export class GroupService extends GroupData {
    constructor(private http: HttpClient,
    ) {
        super();
    }

    getById(groupId: string): Observable<Group> {
        return this.http.get<Group>(
            `/api/v1/groups/${groupId}`
        );
    }

    list(): Observable<Group[]> {
        return this.http.get<Group[]>(
            `/api/v1/groups`
        );
    }

    create(group: Group): Observable<any> {
        return this.http.post(`/api/v1/groups`, group);
    }

    addUserToGroup(groupId: string, user: User): Observable<any> {
        return this.http.post(`/api/v1/groups/${groupId}/user`, user);
    }

    addOrganizationToGroup(groupId: string, organization: Organization): Observable<any> {
        return this.http.post(`/api/v1/groups/${groupId}/organization`, organization);
    }

    getByOwner(owner: string): Observable<Organization[]> {
        return this.http.get<Organization[]>(
            `/api/v1/groups/user/${owner}`
        );
    }

    removeUserFromGroup(groupId: string, userId: string): Observable<any> {
        return this.http.post(`/api/v1/groups/${groupId}/user/${userId}`, {});
    }

    removeOrganizationFromGroup(groupId: string, organizationId: string): Observable<any> {
        return this.http.post(`/api/v1/groups/${groupId}/organization/${organizationId}`, {});
    }
}
