import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { throwIfAlreadyLoaded } from './guards/module-import-guard';
import {
  LayoutService,
  PlayerService,
  StateService,
} from './utils';
import { UserData } from './data/users.data';
import { ReportsData } from './data/reports-data';
import { UserService } from './services/users.service';
import { DashboardService } from './services/dashboard.service';
import { AccountData } from './data/account.data';
import { AccountService } from './services/account.service';
import { StorageUtils } from './utils/storage.utils';
import { CustomHttpInterceptor } from './interceptors/custom-http.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrganizationData } from './data/organization.data';
import { OrganizationService } from './services/organization.service';
import { NotificacationUtils } from './utils/notification.utils';
import { AuthGuard } from './guards/auth-guard';
import { ReportDataService } from './services/report.service';
import { IntegrationData } from './data/integration.data';
import { IntegrationService } from './services/integration.service';
import { DateTimeUtils } from './utils/datetime.utils';
import { Exportutils } from './utils/export.utils';
import { GroupData } from './data/group.data';
import { GroupService } from './services/group.service';
import { RoleProvider } from './roles/role.provider';
import { DashboardData } from './data/dashboard-data';
import { DeviceDataData } from './data/devices.data';
import { DeviceService } from './services/device.service';

const SERVICES = [
  UserService,
  DashboardService,
  AccountService,
  OrganizationService,
  ReportDataService,
  IntegrationService
];

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: DashboardData, useClass: DashboardService },
  { provide: AccountData, useClass: AccountService },
  { provide: OrganizationData, useClass: OrganizationService },
  { provide: ReportsData, useClass: ReportDataService },
  { provide: IntegrationData, useClass: IntegrationService },
  { provide: GroupData, useClass: GroupService },
  { provide: DeviceDataData, useClass: DeviceService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    return observableOf('guest');
  }
}

export const CORE_PROVIDERS = [
  ...SERVICES,
  ...DATA_SERVICES,
  NbSecurityModule.forRoot({
    accessControl: {
      "discoverpay:user": {
        read: ['test'],
      },
      "discoverpay:admin": {
        parent: 'discoverpay:user',
        read: ['groups', 'organization-users', 'organizations', 'terminals', 'equipments', 'admin'],
      },
    },
  }).providers,
  AuthGuard,
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  LayoutService,
  PlayerService,
  StateService,
  StorageUtils,
  NotificacationUtils,
  DateTimeUtils,
  Exportutils
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...CORE_PROVIDERS,
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
        { provide: NbRoleProvider, useClass: RoleProvider },
      ],
    };
  }
}
