import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportsData } from '../data/reports-data';
import { DateTimeUtils } from '../utils/datetime.utils';

@Injectable()
export class ReportDataService extends ReportsData {

    constructor(private http: HttpClient,
        private datetimeUtils: DateTimeUtils
    ) {
        super();
    }

    GetReceivablesReport(organizationId: string, startPaymentDate: Date, endPaymentDate: Date): Observable<any[]> {
        return this.http.get<any[]>(
            `/api/v1/reports-data/organization/${organizationId}/receivables?startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startPaymentDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endPaymentDate, 'yyyy-MM-ddTHH:mm:ss')}`
        );
    }

    GetSalesReport(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any[]> {
        return this.http.get<any[]>(
            `/api/v1/transactions/organization/${organizationId}?startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&status=1&productList=001,005`
        );

    }

    GetTransactions(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any[]> {
        return this.http.get<any[]>(
            `/api/v1/transactions/organization/${organizationId}?startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`
        );

    }

    GetOrders(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId: string): Observable<any[]> {
        let queryParams = `startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`


        queryParams += `&organizationId=${organizationId}`


        return this.http.get<any[]>(
            `/api/v1/reports-data/orders?${queryParams}`
        );

    }


    GetDetailedReport(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId: string, groupId?: string): Observable<any[]> {
        let queryParams = `startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`

        if (groupId) {
            queryParams += `&groupId=${groupId}`
        }
        else {
            queryParams += `&organizationId=${organizationId}`
        }

        return this.http.get<any[]>(
            `/api/v1/reports-data/detailed?${queryParams}`
        );

    }

    GetPixTransactions(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any[]> {
        return this.http.get<any[]>(
            `/api/v1/pix-transactions/organization/${organizationId}?startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`
        );

    }

    ExportReceivablesReportCsv(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any> {
        return this.http.get<any[]>(
            `/api/v1/receivables/organization/${organizationId}/reports/receivables/csv?startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`
        );
    }

    DownloadOrderReportFile(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId: string): Observable<any> {

        let queryParams = `startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`


        queryParams += `&organizationId=${organizationId}`


        const headers = {
            'Accept': 'text/plain',
        }

        return this.http.get(`/api/v1/reports-data/orders?${queryParams}`, {
            headers: headers,
            responseType: 'blob' as 'json'
        });
    }


    DownloadDetailReportFile(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId?: string, groupId?: string): Observable<any> {

        let queryParams = `startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`

        if (groupId) {
            queryParams += `&groupId=${groupId}`
        }
        else {
            queryParams += `&organizationId=${organizationId}`
        }

        const headers = {
            'Accept': 'application/vnd.ms-excel',
        }

        return this.http.get(`/api/v1/reports-data/detailed?${queryParams}`, {
            headers: headers,
            responseType: 'blob' as 'json'
        });
    }

    DownloadFile(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any> {
        const baseUrl = `/api/v1/reports-data/organization/${organizationId}/receivables?startAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(startAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}&endAuthorizationDate=${this.datetimeUtils.toPtBrDatetime(endAuthorizationDate, 'yyyy-MM-ddTHH:mm:ss')}`;
        const headers = {
            'Accept': 'erp-control/text/csv',
        }
        return this.http.get(baseUrl, {
            headers: headers,
            responseType: 'blob' as 'json'
        });
    }
}
