import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";


@Injectable()
export class DateTimeUtils {
    public toPtBrDatetime(datetime: Date, format: string) {
        const parsedDatetime = new DatePipe("pt-BR").transform(datetime, format);
        return parsedDatetime;
    }
}