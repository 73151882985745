import { Observable } from 'rxjs';

export interface LatestSales {
  paymentDate: string;
  paymentDatePtBr: string;
  productName: string;
  amount: number;
  amountLocal: number;
}


export interface TransactionSummary {
  all: number;
  paymentHour: string;
  processed: number;
  cancelled: number;
  amount: number;
  creditTransactions: number;
  debitTransactions: number;
}

export abstract class DashboardData {
  abstract getLatest7Transactions(organizationId?: string, groupId?: string): Observable<LatestSales[]>;
  abstract getTodayTransactionSummary(organizationId?: string, groupId?: string): Observable<TransactionSummary[]>;
}
