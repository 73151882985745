import { Injectable, ElementRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

export class TableColumn {
  field: string;
  title: string;
  isExport: boolean;
  valuePrepareFunction: Function;
}

export interface ExcelJson {
  data: Array<any>;
  header?: Array<string>;
  skipHeader?: boolean;
  origin?: string | number;
}

@Injectable()
export class Exportutils {
  constructor() { }

  /**
   * Creates excel from the table element reference.
   *
   * @param element DOM table element reference.
   * @param fileName filename to save as.
   */
  public exportTableElmToExcel(element: ElementRef, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element.nativeElement);
    // generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);

  }

  /**
   * Creates XLSX option from the Json data. Use this to customise the sheet by adding arbitrary rows and columns.
   *
   * @param json Json data to create xlsx.
   * @param fileName filename to save as.
   */
  public exportJsonToExcel(json: ExcelJson[], fileName: string): void {
    // inserting first blank row
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      json[0].data,
      this.getOptions(json[0])
    );

    for (let i = 1, length = json.length; i < length; i++) {
      // adding a dummy row for separation
      XLSX.utils.sheet_add_json(
        worksheet,
        [{}],
        this.getOptions(
          {
            data: [],
            skipHeader: true
          }, -1)
      );
      XLSX.utils.sheet_add_json(
        worksheet,
        json[i].data,
        this.getOptions(json[i], -1)
      );
    }
    const workbook: XLSX.WorkBook = { Sheets: { Sheet1: worksheet }, SheetNames: ['Sheet1'] };
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);
  }

  /**
   * Creates XLSX option from the data.
   *
   * @param json Json data to create xlsx.
   * @param origin XLSX option origin.
   * @returns options XLSX options.
   */
  private getOptions(json: ExcelJson, origin?: number): any {
    // adding actual data
    const options = {
      skipHeader: true,
      origin: -1,
      header: []
    };
    options.skipHeader = json.skipHeader ? json.skipHeader : false;
    if (!options.skipHeader && json.header && json.header.length) {
      options.header = json.header;
    }
    if (origin) {
      options.origin = origin ? origin : -1;
    }
    return options;
  }

  /**
   * Saves the file on client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  /**
   * Creates an array of data to csv. It will automatically generate title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ';';
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }

  prepareColumnMap(settingColumns: any): Map<string, TableColumn> {
    const columnMap: Map<string, TableColumn> = new Map<string, TableColumn>();
    for (const key in settingColumns) {
      if (!settingColumns.hasOwnProperty(key)) {
        continue;
      }
      const column: TableColumn = new TableColumn();
      column.title = settingColumns[key]['title'];
      column.field = key;
      column.isExport = settingColumns[key]['isExport'];
      column.valuePrepareFunction = settingColumns[key]['valuePrepareFunction'];
      columnMap.set(column.field, column);
    }
    return columnMap;
  }

  exportToCsvFromTable(source: any, settingColumns: any, exportName: string, delimiter: string = ",") {
    const columnMap = this.prepareColumnMap(settingColumns.columns);
    const columns: TableColumn[] = Array.from(columnMap.values());

    let encodedStr = columns.reduce((acct, current: TableColumn) => {
      if (current.isExport !== false) {
        return acct += current.title + `${delimiter}`;
      } else {
        return acct;
      }
    }, '');

    encodedStr = encodedStr.slice(0, -1);
    encodedStr += '\r\n';

    const fields: string[] = columns.reduce((acct, column: TableColumn) => {
      if (column.isExport !== false) {
        acct.push(column.field);
      }
      return acct;
    }, []);

    let i = 0;
    source.getAll().then((rows: any) => {
      rows.forEach((row: any) => {
        fields.forEach((field) => {
          if (row.hasOwnProperty(field)) {
            let value = row[field];

            if (value) {
              const valuePrepare = columnMap.get(field).valuePrepareFunction;
              if (valuePrepare) {
                value = valuePrepare.call(null, value, row);
              }
            }

            if (!value) {
              value = '';
            }

            encodedStr += value + `${delimiter}`;

          }
        });
        encodedStr = encodedStr.slice(0, -1);
        encodedStr += '\r\n';
        i++;
      });

      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      // Set utf-8 header to let excel recognize its encoding
      const blob = new Blob(['\ufeff', encodedStr], { type: 'text/csv;charset=utf-8;' });
      a.href = window.URL.createObjectURL(blob);
      a.download = (exportName) + '.csv';
      a.click();
    });
  }
}