import { Injectable } from '@angular/core';
import { Organization, OrganizationData } from '../data/organization.data';
import { HttpClient } from '@angular/common/http';
import { StorageUtils } from '../utils/storage.utils';
import { of as observableOf, Observable } from 'rxjs';

@Injectable()
export class OrganizationService extends OrganizationData {
    private readonly selectedKey = "org:selected";
    constructor(private http: HttpClient,
        private storage: StorageUtils
    ) {
        super();
    }

    get(organizationId: string): Observable<Organization> {
        return this.http.get<Organization>(
            `/api/v1/organizations/${organizationId}`
        );
    }

    getAll(): Observable<Organization[]> {
        return this.http.get<Organization[]>(
            `/api/v1/organizations`
        );
    }
    create(organization: Organization): Observable<any> {
        return this.http.post(`/api/v1/organizations`, organization);
    }

    inviteUser(organizationId: string, email: string): Observable<any> {
        return this.http.post(`/api/v1/organizations/${organizationId}/users/${email}/invite`, {});
    }

    removeUser(organizationId: string, userId: string): Observable<any> {
        return this.http.put(`/api/v1/organizations/${organizationId}/users/${userId}/remove`, {});
    }

    addUser(organizationId: string, userId: string): Observable<any> {
        return this.http.put(`/api/v1/organizations/${organizationId}/users/${userId}/add`, {});
    }

    getByOwner(owner: string): Observable<Organization[]> {
        return this.http.get<Organization[]>(
            `/api/v1/organizations/user/${owner}`
        );
    }

    update(organization: Organization): Observable<string> {
        return this.http.put(`/api/v1/organizations`, organization, { responseType: 'text' });
    }

    getSelectedId(): Observable<string> {
        return observableOf(this.storage.get<string>(this.selectedKey))
    }

    getSelected(): Observable<Organization> {
        const orgId = this.storage.get<string>(this.selectedKey)
        return this.http.get<Organization>(
            `/api/v1/organizations/${orgId}`
        );
    }

    setSelected(orgkey: string): Observable<boolean> {
        this.storage.set(this.selectedKey, orgkey);
        return observableOf(true)
    }

    getByGroupId(groupId: string): Observable<Organization[]> {
        return this.http.get<Organization[]>(
            `/api/v1/organizations/group/${groupId}`
        );
    }
}
