import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NbIconLibraries, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountService } from '../../../@core/services/account.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Identity } from '../../../@core/data/account.data';
import { Organization, OrganizationData } from '../../../@core/data/organization.data';
import { Group, GroupData } from '../../../@core/data/group.data';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private readonly accountService: AccountService;
  private readonly orgService: OrganizationData;

  identity: Identity;
  organizations: Organization[];
  groups: Group[];
  contextSelected: string = "";
  currentTheme = 'default';

  userProfileMenu = [{ title: 'Profile', link: 'manage-profile', },
  { title: 'Sair', link: '/account/logout' }];

  groupsMenu = [{ title: 'Criar grupo', link: 'admin/groups/create', },
  { title: 'Listar grupos Lojistas', link: 'admin/groups' }];

  equipmentsMenu = [{ title: 'Cadastrar Equipamento', link: 'admin/equipments/create', },
  { title: 'Listar equipamentos', link: 'admin/equipments' }];

  orgsMenu = [{ title: 'Listar lojistas', link: 'organizations' }, { title: 'Usuários', link: '', hidden: true }, { title: 'Integração Stone', link: '', hidden: true }];

  adminMenu = [{ title: 'DISCOVERPAY ADMIN', link: 'admin' }];

  showOrgMenu = false;

  lastUrl = "";

  constructor(private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    iconsLibrary: NbIconLibraries,
    acctService: AccountService,
    organizationService: OrganizationData,
    protected router: Router,
    private groupService: GroupData,
    private route: ActivatedRoute
  ) {
    iconsLibrary.registerFontPack('ion', { iconClassPrefix: 'ion' });
    this.accountService = acctService;
    this.orgService = organizationService;
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.accountService.getIdentity()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: Identity) => {
        this.identity = result;

        this.orgService.getByOwner(result?.id ?? "")
          .pipe()
          .subscribe((orgs: Organization[]) => this.organizations = orgs);

        this.groupService.getByOwner(result?.id ?? "")
          .pipe()
          .subscribe((orgs: Organization[]) => this.groups = orgs);
      });

    this.route.params
      .subscribe(params => {
        this.contextSelected = params.oid ?? `G*${params.gid}`;
        this.updateMenu(this.contextSelected);
      });

    if (this.router.url.indexOf('admin') > -1) {
      this.showOrgMenu = false;
    }
    else {
      this.showOrgMenu = true;
    }

    localStorage.setItem("last_url", this.router.url);
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateMenu(context: string) {
    let isGroup = context.substring(0, 2) === "G*";
    if (!isGroup && context) {
      this.orgsMenu[1].link = `admin/o/${this.contextSelected}/users`;
      this.orgsMenu[1].hidden = false;
      this.orgsMenu[2].link = `admin/o/${this.contextSelected}/integrations/stone`;
      this.orgsMenu[2].hidden = false;

      // this.adminMenu[0].link = `admin`;
    }
  }

  isOrgSelected() {
    return this.contextSelected == null ? false : true;
  }

  changeOrg(value: string) {
    this.contextSelected = value;
    this.orgService.setSelected(value);
    this.updateMenu(value);
    this.navigateDashboard(value);
  }

  navigateDashboard(value: string) {

    const isGroup = value.substring(0, 2) === "G*";
    const parsedSelected = value.replace("G*", "");

    if (isGroup) {
      const groupUrl = `/g/${parsedSelected}/dashboard`;
      localStorage.setItem("last_url", groupUrl);
      this.router.navigate(
        [groupUrl],);
      return;
    }

    const orgUrl = `/o/${parsedSelected}/dashboard`;
    localStorage.setItem("last_url", orgUrl);
    this.router.navigate(
      [orgUrl],);
  }

  onChangeEditOrganization() {
    this.router.navigateByUrl(`/o/${this.contextSelected}`);
  }
  onChangeNewOrganization() {
    this.router.navigateByUrl("/o/create");
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.navigateDashboard(this.contextSelected);
    return false;
  }
}
