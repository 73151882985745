import { Observable } from "rxjs";

export interface Auth {
  role: string;
  token: string;
  TokenExpiresIn: number;
}

export interface Identity {
  id?: string;
  name?: string;
  emai?: string;
}

export interface Account {
  email?: string;
  password?: string;
  fullName?: string;
  confirmPassword?: string;
}

export abstract class AccountData {
  abstract authenticate(email: string, password: string): Observable<Auth>;
  abstract isAuthenticated(): Observable<boolean>;
  abstract getToken(): string;
  abstract register(account: Account): Observable<Account>;
  abstract resetPassword(email: string): any;
  abstract changePassword(token: string, password: string): any;
}