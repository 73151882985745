import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User, UserData } from '../data/users.data';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService extends UserData {


  constructor(private http: HttpClient,
  ) {
    super();
  }

  getUsersByOrganization(key: string): Observable<User[]> {
    return this.http.get<User[]>(`/api/v1/users/organization/${key}`);
  }

  getUsersByGroup(groupId: string): Observable<User[]> {
    return this.http.get<User[]>(`/api/v1/users/group/${groupId}`);
  }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>('/api/v1/users');
  }
}
