import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">        
    <b><a href="https://www.discoverpay.com.br/" target="_blank">Discoverpay</a></b> Copyright 2023
    </span>
    <div class="socials">
      <a href="https://github.com/discover-pay" target="_blank" class="ion ion-social-github"></a>      
      <a href="https://www.linkedin.com/company/discoverpay/about/" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
}
