import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceModel, DeviceDataData, Device } from '../data/devices.data';
import { Observable } from 'rxjs';

@Injectable()
export class DeviceService extends DeviceDataData {

  constructor(private http: HttpClient,
  ) {
    super();
  }

  getAllTerminalsModels(): Observable<DeviceModel[]> {
    return this.http.get<DeviceModel[]>(`/api/v1/devices/models`);
  }

  getAllDevices(key: string): Observable<DeviceModel[]> {
    return this.http.get<DeviceModel[]>(`/api/v1/devices`);
  }

  getAll(): Observable<Device[]> {
    return this.http.get<Device[]>(`/api/v1/devices`);
  }

  create(device: Device): Observable<any> {
    return this.http.post<Device[]>('/api/v1/devices', device);
  }
}
