import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of as observableOf } from 'rxjs';
import { NbRoleProvider } from '@nebular/security';
import { StorageUtils } from '../utils/storage.utils';

@Injectable()
export class RoleProvider implements NbRoleProvider {

  constructor(private storage: StorageUtils,) { }

  getRole(): Observable<string[]> {
    const result = this.storage.get<any>("app:jwt-token");
    return observableOf(result?.roles ?? []);
  }
}