<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img alt="log" src="../../../../assets/images/logo-alternative.svg" style="min-width: 164px; min-height: 36px;"
        href="#">
    </a>
  </div>
  <!-- <nb-actions size="small">     -->
  <!-- <nb-action class="nb-action-topbar" [nbContextMenu]="termninalsMenu" *nbIsGranted="['read', 'terminals']">
      <span>Terminais</span>
    </nb-action> -->
  <!-- </nb-actions> -->
</div>
<div class="header-container">
  <nb-select placeholder="Alterar contexto do portal" [selected]="contextSelected" (selectedChange)="changeOrg($event)"
    class="ngx-select-style" size="small" style="min-width: 260px; max-width: 260px;" fullWidth *ngIf="showOrgMenu">
    <nb-option-group title="Grupos" *ngIf="groups?.length > 0">
      <nb-option *ngFor="let grp of groups" [value]="'G*'+grp.key">{{
        grp.name }}
      </nb-option>
    </nb-option-group>
    <br>
    <nb-option-group title="Lojistas">
      <nb-option *ngFor="let org of organizations" [value]="org.key">{{
        org.displayName }}
        <br>
        {{org.document}}
      </nb-option>
    </nb-option-group>
  </nb-select>
  <nb-actions size="small">
    <nb-action class="control-item" icon="settings-2-outline" [nbContextMenu]="adminMenu"
      *nbIsGranted="['read', 'admin']"></nb-action>
    <nb-action class="user-action">
      <nb-user class="nb-user-class" [nbContextMenu]="userProfileMenu" [name]="identity.name">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>