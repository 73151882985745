import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';

import { LatestSales, DashboardData, TransactionSummary } from '../data/dashboard-data';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService extends DashboardData {


  constructor(private http: HttpClient) {
    super();
  }

  getLatest7Transactions(organizationId?: string, groupId?: string): Observable<LatestSales[]> {

    let queryParams = '';
    if (groupId) {
      queryParams += `&groupId=${groupId}`
    }
    else {
      queryParams += `&organizationId=${organizationId}`
    }

    return this.http.get<any[]>(
      `/api/v1/reports-data/dashboard/latest-sales?${queryParams}`
    );
  }

  getTodayTransactionSummary(organizationId?: string, groupId?: string): Observable<TransactionSummary[]> {

    let queryParams = '';
    if (groupId) {
      queryParams += `&groupId=${groupId}`
    }
    else {
      queryParams += `&organizationId=${organizationId}`
    }

    return this.http.get<any[]>(
      `/api/v1/reports-data/dashboard/transaction-summary?${queryParams}`
    );
  }


  getDataLabels(nPoints: number, labelsArray: string[]): string[] {
    const labelsArrayLength = labelsArray.length;
    const step = Math.round(nPoints / labelsArrayLength);

    return Array.from(Array(nPoints)).map((item, index) => {
      const dataIndex = Math.round(index / step);

      return index % step === 0 ? labelsArray[dataIndex] : '';
    });
  }
}
