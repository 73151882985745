import { Observable } from "rxjs";

export abstract class ReportsData {
  abstract GetSalesReport(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any[]>;
  abstract GetReceivablesReport(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any[]>;
  abstract ExportReceivablesReportCsv(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any>;
  abstract DownloadFile(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any>;
  abstract DownloadDetailReportFile(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId?: string, groupId?: string): Observable<any>;
  abstract DownloadOrderReportFile(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId: string): Observable<any>;
  abstract GetTransactions(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any[]>;
  abstract GetOrders(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId: string): Observable<any[]>;
  abstract GetDetailedReport(startAuthorizationDate: Date, endAuthorizationDate: Date, organizationId?: string, groupId?: string): Observable<any[]>;
  abstract GetPixTransactions(organizationId: string, startAuthorizationDate: Date, endAuthorizationDate: Date): Observable<any[]>;
}

