import { Observable } from "rxjs";
import { User } from "./users.data";
import { Organization } from "./organization.data";

export interface Group {
    key?: string;
    name?: string;
    ownerId?: string;
}

export abstract class GroupData {
    abstract getById(groupId: string): Observable<Group>;
    abstract list(): Observable<Group[]>;
    abstract create(group: Group): Observable<any>;
    abstract addUserToGroup(groupId: string, user: User): Observable<any>;
    abstract addOrganizationToGroup(groupId: string, organization: Organization): Observable<any>;
    abstract getByOwner(owner: string): Observable<Group[]>;
    abstract removeUserFromGroup(groupId: string, userId: string): Observable<any>;
    abstract removeOrganizationFromGroup(groupId: string, organizationId: string): Observable<any>;
}
