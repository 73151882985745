import { Observable } from "rxjs";

export interface Organization {
    key?: string;
    name?: string;
    displayName?: string;
    description?: string;
    document?: string;
    isEnabled?: boolean;
    phone?: string;
    email?: string;
    ownerId?: string;
    phoebusMerchantId?: string;
    stoneCode?: string;
}

export abstract class OrganizationData {
    abstract get(organizationId: string): Observable<Organization>;
    abstract getAll(): Observable<Organization[]>;
    abstract create(organization: Organization): Observable<any>;
    abstract getByOwner(owner: string): Observable<Organization[]>;
    abstract getByGroupId(groupId: string): Observable<Organization[]>;
    abstract update(organization: Organization): Observable<string>
    abstract setSelected(orgKey: string): Observable<boolean>;
    abstract getSelectedId(): Observable<string>;
    abstract getSelected(): Observable<Organization>;
    abstract inviteUser(organizationId: string, email: string): Observable<any>;
    abstract removeUser(organizationId: string, userId: string): Observable<any>;
    abstract addUser(organizationId: string, userId: string): Observable<any>;
}
