import { Injectable } from "@angular/core";
import { NbGlobalPhysicalPosition, NbIconConfig, NbToastrService } from "@nebular/theme";
import { config } from "rxjs";

@Injectable()
export class NotificacationUtils {
    constructor(
        private toastrService: NbToastrService
    ) {
    }

    public success(message: string) {
        const iconConfig: NbIconConfig = { status: "primary", icon: "checkmark-outline", pack: 'eva' };
        this.toastrService.show("", message, iconConfig);

    }
    public error(message: string) {
        const iconConfig: NbIconConfig = { status: "danger", icon: "alert-triangle-outline", pack: 'eva' };
        this.toastrService.show("", message, iconConfig);
    }
}