import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageUtils {

    set<T>(key: string, value: T) {
        const content = JSON.stringify(value);
        localStorage.setItem(key, content);
    }

    get<T>(key: string) {
        const content = localStorage.getItem(key);
        if (content == null) {
            return null;
        }
        const value: T = JSON.parse(content) as T;
        return value;
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}
