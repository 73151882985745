import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account, AccountData, Auth, Identity } from '../data/account.data';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { StorageUtils } from '../utils/storage.utils';
import { Router } from '@angular/router';
import jwtDecode from "jwt-decode";

@Injectable()
export class AccountService extends AccountData {
    private readonly storage: StorageUtils;
    private readonly storageKey: string;
    constructor(private http: HttpClient,
        storage: StorageUtils,
        private router: Router,
    ) {
        super();
        this.storage = storage;
        this.storageKey = "app:jwt-token";
    }

    authenticate(email: string, password: string): Observable<Auth> {
        return this.createAccessToken(email, password)
            .pipe(
                map(apiResult => {
                    const jwt = jwtDecode<any>(apiResult.token);
                    jwt.token = apiResult.token;
                    this.storage.set<any>(this.storageKey, jwt);
                    return apiResult;
                }));
    }

    getIdentity(): Observable<Identity> {
        const identity = this.storage.get<any>(this.storageKey) as Identity;
        return observableOf(identity);
    }

    isAuthenticated(): Observable<boolean> {
        return this.isValid();
    }

    getToken(): string {
        const jwt = this.storage.get<any>(this.storageKey);
        return jwt?.token ?? "";
    }

    logout(): Observable<boolean> {
        this.storage.remove(this.storageKey);
        // this.storage.remove("org:selected")
        this.router.navigateByUrl('account/login');
        return observableOf(true);
    }

    DecodeToken(token: string): string {
        return jwtDecode(token);
    }

    public register(account: Account): any {
        return this.invokeRegistration(account).pipe(
            map(apiResult => {
                return apiResult;
            })
        );
    }

    public resetPassword(email: string): any {
        return this.invokePasswordReset(email).pipe(
            map(apiResult => {
                return apiResult;
            })
        );
    }

    public changePassword(token: string, password: string): any {
        return this.invokePasswordChange(token, password).pipe(
            map(apiResult => {
                return apiResult;
            })
        );
    }

    public verifyToken(token: string): Observable<Account> {
        return this.http.get<any>(`/api/v1/account/verify-account`, {
            params: { token: token },
        });
    }

    private invokePasswordChange(token: string, password: string): Observable<Account> {
        const accountPayload = new HttpParams()
            .set('password', password);
        return this.http
            .post<Account>(
                '/api/v1/account/change-password',
                accountPayload,
                {
                    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
                    withCredentials: true,
                    params: { token: token },
                }
            );
    }

    private invokePasswordReset(email: string): Observable<any> {
        return this.http
            .get<any>(
                `/api/v1/account/request-password-reset/${email}`
            );
    }

    private invokeRegistration(account: Account): Observable<Account> {
        const accountPayload = new HttpParams()
            .set('name', account.fullName)
            .set('email', account.email)
            .set('password', account.password);
        return this.http
            .post<Account>(
                '/api/v1/account/register',
                accountPayload,
                {
                    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
                    withCredentials: true,
                }
            );
    }

    private isValid(): Observable<boolean> {
        return observableOf(this.verifyTokenInfo());
    }

    private verifyTokenInfo(): boolean {
        const decoded = this.storage.get<any>(this.storageKey);

        if (!decoded) {
            return false;
        }

        if (Date.now() >= decoded.exp * 1000) {
            return false;
        }

        return true;
    }

    private createAccessToken(email: string, password: string): Observable<Auth> {
        const auth = new HttpParams()
            .set('email', email)
            .set('password', password);
        return this.http
            .post<Auth>(
                '/api/v1/auth/token',
                auth,
                {
                    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
                    withCredentials: true,
                }
            );
    }
}
